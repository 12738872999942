@import "asset";
@import "vars";
@import "~@/plugins/parallax/less/prx";

.helvetica() { font-family: 'Helvetica Neue', sans-serif; }
.fvh() { height: calc(var(--vh, 1vh) * 100); }

.appear-ts(@v) { transition: transform unit(@v, s) ease, opacity unit(@v, s) ease; }
.appear-ts(@v,@ease) { transition: transform unit(@v, s) @ease, opacity unit(@v, s) ease; }
.appear-delay(@v) {
  .tr-d(unit(@v * 0.4, s));
}

.ff-kr(){font-family:'NotoSans-kr', sans-serif;}
.ff-en(){font-family:'Helvetica Now', sans-serif;}
.ff-ja(){font-family:'NotoSans-jp', sans-serif;}
.ff-vi(){font-family:'NotoSans', sans-serif;}
.ff-zh(){font-family:'NotoSans-tc', sans-serif;}
.ff-hvd-black(){font-family:'Helvetica-Now-Display-Black', sans-serif;}


.m-mw(){.m(0,18)} //모바일 가로 여백
.t-mw(){.w(480); .m(0,auto); .-box;} //타블
.pc-mw(){.w(650); .m(0,auto); .-box;} //타블// 렛 768 가로 여백

.m-m(){.pl(18); .pr(18);}
.t-m(){.pl(36); .pr(36);}
.pc-m(){.pl(48); .pr(48);}
.pcl-m(){.pl(60); .pr(60);}

.proj-max-w(){.max-w(2680); .mh-c; .-box;}//프로젝트 max-width / 최대 2560, 양쪽여백(120) 더해서 2680
