.t {
  transform: none;
}
[play-list] {
  padding-bottom: 8.8125rem;
}
[play-list] section.hero {
  padding-top: 8.25rem;
}
[play-list] section.hero .categories {
  overflow: hidden;
  height: 3rem;
}
[play-list] section.hero .categories .swiper-container {
  box-sizing: border-box;
  overflow: visible;
}
[play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide {
  width: auto;
}
[play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .slider-item a {
  color: #fff;
  -webkit-text-stroke: 1px #fff;
}
[play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item {
  margin-top: 0rem;
  margin-right: 1.5rem;
  margin-bottom: 0rem;
  margin-left: 1.5rem;
  min-width: 9.375rem;
}
[play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item a {
  text-align: center;
  display: block;
  font-size: 3rem;
  line-height: 3rem;
  text-transform: uppercase;
  font-family: 'Helvetica-Now-Display-Black', sans-serif;
  -webkit-text-stroke: 1px #ebebeb;
  color: #000;
  transition: color 0.4s ease;
}
[play-list] section.hero .description {
  margin-top: 0.625rem;
  font-weight: 500;
  padding-top: 0rem;
  padding-right: 1.8125rem;
  padding-bottom: 0rem;
  padding-left: 1.8125rem;
  text-align: center;
  font-size: 1rem;
  line-height: 0.09375rem;
  letter-spacing: -0.025rem;
  line-height: 1.5;
}
[play-list] section.list {
  position: relative;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
[play-list] section.list .sticky {
  position: sticky;
  margin-top: 2rem;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  padding-top: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
  padding-left: 0rem;
  z-index: 2;
  background: #000;
}
[play-list] section.list .sub-categories {
  text-align: center;
  overflow: hidden;
}
[play-list] section.list .sub-categories .locked .swiper-slide:last-of-type {
  margin-right: 0rem;
}
[play-list] section.list .sub-categories .swiper-slide {
  padding: 0;
  overflow: hidden;
}
[play-list] section.list .sub-categories .swiper-slide:last-of-type {
  margin-right: 4.6875rem;
}
[play-list] section.list .sub-categories .swiper-slide a {
  border: 1px solid #fff;
  font-size: 0.875rem;
  padding-top: 0.1875rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  display: block;
  font-weight: 500;
}
[play-list] section.list .sub-categories .swiper-slide a.on {
  background-color: #fff;
  color: #000;
}
[play-list] section.list .sub-categories [scroll-menu].locked .swiper-wrapper {
  justify-content: center;
}
[play-list] section.list .sub-categories [scroll-menu].locked + .more {
  display: none;
}
[play-list] section.list .sub-categories .more {
  width: 4.6875rem;
  height: 100%;
  z-index: 9;
  position: absolute;
  right: 0rem;
  top: 0rem;
  left: auto;
  bottom: auto;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000 65%);
}
[play-list] section.list .sub-categories .more i {
  position: absolute;
  right: 0.625rem;
  top: 50%;
  left: auto;
  bottom: auto;
  transform: rotate(0deg) translateY(-50%);
  width: 1rem;
  height: 1rem;
  background-image: url('/img/common/ic-down-arrow-dark.png');
  background-size: contain;
  background-repeat: no-repeat;
}
[play-list] section.list .lowest-categories {
  margin-top: 2.75rem;
  position: relative;
  overflow: hidden;
}
[play-list] section.list .lowest-categories .swiper-slide:last-of-type {
  margin-right: 4.6875rem;
}
[play-list] section.list .lowest-categories .swiper-slide a {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #666;
  position: relative;
  padding-bottom: 0.1875rem;
}
[play-list] section.list .lowest-categories .swiper-slide a.on {
  color: #fff;
}
[play-list] section.list .lowest-categories .swiper-slide a.on:after {
  width: 100%;
  background-color: #fff;
}
[play-list] section.list .lowest-categories .swiper-slide a:after {
  content: '';
  display: block;
  position: absolute;
  height: 0.0625rem;
  width: 0rem;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  background-color: #666;
}
.no-touch [play-list] section.list .lowest-categories .swiper-slide a:hover:after {
  width: 100%;
}
[play-list] section.list .lowest-categories p {
  height: 100%;
  width: 75px;
  z-index: 9;
  position: absolute;
  right: 0rem;
  top: 0rem;
  left: auto;
  bottom: auto;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #000 65%);
}
[play-list] section.list .lowest-categories + ul {
  padding-top: 1.875rem;
}
[play-list] section.list > ul {
  position: relative;
  padding-top: 4.1875rem;
  display: grid;
  grid-template-columns: 1fr;
}
[play-list] section.list > ul > li {
  position: relative;
  overflow: hidden;
  aspect-ratio: 0.6;
  margin-bottom: 1.25rem;
}
[play-list] section.list > ul > li [cdn-img] {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
  transform: scale(1);
  opacity: 1;
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04), opacity 0.6s ease;
}
[play-list] section.list > ul > li .over-video {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 1s cubic-bezier(0, 0, 0.13, 1.04);
}
[play-list] section.list > ul > li .text {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  padding-top: 2.25rem;
  padding-right: 1.5rem;
  padding-bottom: 2.25rem;
  padding-left: 1.5rem;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
[play-list] section.list > ul > li .text p:first-of-type {
  font-size: 0.75rem;
  padding-bottom: 0.75rem;
}
[play-list] section.list > ul > li .text p:first-of-type span {
  font-weight: 500;
  display: inline-block;
  margin-left: 0.5rem;
}
[play-list] section.list > ul > li .text p:last-of-type {
  font-size: 1.125rem;
  line-height: 0.100625rem;
  letter-spacing: -0.015625rem;
  line-height: 1.61;
  font-weight: 700;
}
[play-list] section.list > ul > li:last-of-type {
  margin-bottom: 0rem;
}
[play-list] section.list > ul > li .sns-wrap {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 4.375rem;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}
[play-list] section.list > ul > li .sns-wrap [sns-share] {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.75rem;
  margin-right: 0.875rem;
  margin-bottom: 0.75rem;
  margin-left: 0.875rem;
  position: absolute;
  right: 0rem;
  top: 0rem;
  left: auto;
  bottom: auto;
}
[play-list] section.list > ul > li .sns-wrap [sns-share] a.btn {
  background-image: url('/img/common/ic-share-more.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.no-touch [play-list] section.list > ul > li:hover [cdn-img],
.no-touch [play-list] section.list > ul > li:hover video {
  transform: scale(1.1);
}
.no-touch [play-list] section.list > ul > li:hover.has-video [cdn-img] {
  opacity: 0;
}
[play-list] section.list .view-more {
  text-align: center;
  margin-top: 6rem;
}
[play-list] section.list .view-more a {
  position: relative;
}
[play-list] section.list .view-more a span {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.25rem;
  line-height: 0.06875rem;
  line-height: 1.1;
  font-weight: 700;
}
[play-list] section.list .view-more a span:last-of-type {
  margin-left: 0.75rem;
}
[play-list] section.list .view-more a i {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/img/common/ic-white-more.png');
}
[play-list] section.list .view-more a:after {
  content: '';
  position: absolute;
  width: 0rem;
  height: 0.125rem;
  background-color: #fff;
  left: 0rem;
  bottom: -0.625rem;
  right: auto;
  top: auto;
  transition: width 0.6s cubic-bezier(0.4, -0.03, 0, 1.03);
}
.no-touch [play-list] section.list .view-more a:hover:after {
  width: 100%;
}
[play-list] .category-popup {
  position: fixed;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  z-index: 99;
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  transition: transform 0s ease;
  transition-delay: 1s;
  overflow: hidden;
}
[play-list] .category-popup.open {
  transition-delay: 0s;
  transform: translateY(0%);
}
[play-list] .category-popup.open .dimed {
  opacity: 0.65;
}
[play-list] .category-popup.open .holder {
  transform: translateY(0%);
}
[play-list] .category-popup .dimed {
  background-color: #000;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease;
}
[play-list] .category-popup .holder {
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  background-color: #000;
  width: 100%;
  box-sizing: border-box;
  padding-top: 1.125rem;
  padding-right: 1.125rem;
  padding-bottom: 0.125rem;
  padding-left: 1.125rem;
  z-index: 2;
  transform: translateY(100%);
  transition: transform 0.6s ease;
}
[play-list] .category-popup label {
  display: block;
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
}
[play-list] .category-popup ul li {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
[play-list] .category-popup ul li a {
  border: 1px solid #fff;
  font-size: 0.875rem;
  padding-top: 0.1875rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  display: block;
  font-weight: 500;
}
[play-list] .category-popup ul li a.on {
  background-color: #fff;
  color: #000;
}
@media (min-width: 768px) {
  [play-list] {
    padding-bottom: 11.25rem;
  }
  [play-list] section.hero .categories {
    height: 7.125rem;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item {
    margin-top: 0rem;
    margin-right: 2.5rem;
    margin-bottom: 0rem;
    margin-left: 2.5rem;
    min-width: 12.5rem;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item a {
    font-size: 4.75rem;
    line-height: 0.09375rem;
    line-height: 1.5;
  }
  [play-list] section.hero .description {
    margin-top: 0.25rem;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.025rem;
    line-height: 1.5;
  }
  [play-list] section.list {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  [play-list] section.list .sticky {
    position: relative;
    padding: 0;
    margin-top: 2.875rem;
  }
  [play-list] section.list .sub-categories .swiper-slide {
    margin-top: 0.875rem;
    display: inline-block;
  }
  [play-list] section.list .sub-categories .swiper-slide:last-of-type {
    margin-right: 0rem;
  }
  [play-list] section.list .sub-categories .more {
    display: none;
  }
  [play-list] section.list .lowest-categories {
    margin-top: 5.8125rem;
  }
  [play-list] section.list .lowest-categories [scroll-menu] .swiper-slide + .swiper-slide {
    margin-left: 1.25rem;
  }
  [play-list] section.list .lowest-categories .locked {
    width: 100%;
    max-width: none;
  }
  [play-list] section.list .lowest-categories .swiper-slide {
    display: inline-block;
  }
  [play-list] section.list .lowest-categories .swiper-slide:last-of-type {
    margin-right: 0rem;
  }
  [play-list] section.list .lowest-categories .swiper-slide a {
    font-size: 0.875rem;
  }
  [play-list] section.list .lowest-categories p {
    display: none;
  }
  [play-list] section.list .lowest-categories + ul {
    padding-top: 1.5rem;
  }
  [play-list] section.list > ul {
    padding-top: 5.25rem;
    grid-template-columns: 1fr 1fr;
    column-gap: 18px;
  }
  [play-list] section.list > ul > li {
    margin-bottom: 1.125rem;
  }
  [play-list] section.list > ul > li:last-of-type(2) {
    margin-bottom: 0rem;
  }
  [play-list] section.list > ul > li .sns-wrap [sns-share] {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.75rem;
  }
  [play-list] .category-popup {
    display: none;
  }
}
@media (min-width: 1024px) {
  [play-list] {
    padding-bottom: 12.5rem;
  }
  [play-list] section.hero {
    padding-top: 8.75rem;
  }
  [play-list] section.hero .categories {
    height: 8.75rem;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item {
    margin-top: 0rem;
    margin-right: 3.25rem;
    margin-bottom: 0rem;
    margin-left: 3.25rem;
    min-width: 15.625rem;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item a {
    font-size: 5.8125rem;
    line-height: 0.09375rem;
    line-height: 1.5;
  }
  [play-list] section.hero .description {
    margin-top: 0.375rem;
  }
  [play-list] section.list .sticky {
    margin-top: 3.125rem;
  }
  [play-list] section.list .sub-categories .swiper-slide {
    margin-top: 0.875rem;
  }
  [play-list] section.list .sub-categories .more {
    display: none;
  }
  [play-list] section.list .lowest-categories {
    margin-top: 6.25rem;
  }
  [play-list] section.list .lowest-categories + ul {
    padding-top: 1.5rem;
  }
  [play-list] section.list > ul {
    padding-top: 7.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 14px;
  }
  [play-list] section.list > ul > li {
    margin-bottom: 0.875rem;
  }
  [play-list] section.list > ul > li:nth-last-of-type(3) {
    margin-bottom: 0rem;
  }
  [play-list] section.list > ul > li .text {
    padding-top: 1.75rem;
    padding-right: 1.375rem;
    padding-bottom: 1.75rem;
    padding-left: 1.375rem;
  }
  [play-list] section.list > ul > li .sns-wrap [sns-share] {
    margin-top: 0.75rem;
    margin-right: 0.875rem;
    margin-bottom: 0.75rem;
    margin-left: 0.875rem;
  }
  [play-list] section.list .view-more {
    margin-top: 6.3125rem;
  }
}
@media (min-width: 1440px) {
  [play-list] {
    padding-bottom: 17.5rem;
  }
  [play-list] section.hero {
    padding-top: 12.5625rem;
  }
  [play-list] section.hero .categories {
    height: 12.375rem;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item {
    margin-top: 0rem;
    margin-right: 4.5rem;
    margin-bottom: 0rem;
    margin-left: 4.5rem;
    min-width: 23.125rem;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item.swiper-slide-active .slider-item a {
    -webkit-text-stroke: 1.2px #fff;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item a {
    font-size: 8.25rem;
    line-height: 0.09375rem;
    line-height: 1.5;
    -webkit-text-stroke: 1.2px #ebebeb;
  }
  [play-list] section.hero .description {
    font-size: 1.25rem;
    line-height: 0.09375rem;
    letter-spacing: -0.025rem;
    line-height: 1.5;
  }
  [play-list] section.list {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  [play-list] section.list .sticky {
    margin-top: 5.125rem;
  }
  [play-list] section.list .sub-categories .swiper-slide {
    margin-top: 0.875rem;
  }
  [play-list] section.list .lowest-categories {
    margin-top: 5.8125rem;
  }
  [play-list] section.list .lowest-categories [scroll-menu] .swiper-slide + .swiper-slide {
    margin-left: 1.5rem;
  }
  [play-list] section.list .lowest-categories .swiper-slide a {
    font-size: 1rem;
  }
  [play-list] section.list .lowest-categories + ul {
    padding-top: 2rem;
  }
  [play-list] section.list > ul {
    column-gap: 24px;
    padding-top: 10rem;
  }
  [play-list] section.list > ul > li {
    margin-bottom: 1.5rem;
  }
  [play-list] section.list > ul > li .text {
    padding-top: 2.25rem;
    padding-right: 1.875rem;
    padding-bottom: 2.25rem;
    padding-left: 1.875rem;
  }
  [play-list] section.list > ul > li .text p:first-of-type span {
    margin-left: 0.75rem;
  }
  [play-list] section.list > ul > li .text .title {
    font-size: 1.375rem;
    line-height: 0.099375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.59;
  }
  [play-list] section.list > ul > li .sns-wrap {
    height: 6.875rem;
  }
  [play-list] section.list > ul > li .sns-wrap [sns-share] {
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem;
  }
  [play-list] section.list .view-more {
    margin-top: 8.9375rem;
  }
  [play-list] section.list .view-more a span {
    font-size: 1.5rem;
    line-height: 0.0575rem;
    line-height: 0.92;
  }
  [play-list] section.list .view-more a i {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 1.25rem;
  }
}
@media (min-width: 1600px) {
  [play-list] {
    padding-bottom: 18.75rem;
  }
  [play-list] section.hero {
    padding-top: 16rem;
  }
  [play-list] section.hero .categories {
    height: 16.5rem;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item {
    margin-top: 0rem;
    margin-right: 6rem;
    margin-bottom: 0rem;
    margin-left: 6rem;
    min-width: 34.375rem;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item.swiper-slide-active .slider-item a {
    -webkit-text-stroke: 1.6px #fff;
  }
  [play-list] section.hero .categories .swiper-container .swiper-wrapper .swiper-slide .slider-item a {
    font-size: 11rem;
    line-height: 0.09375rem;
    line-height: 1.5;
    -webkit-text-stroke: 1.6px #ebebeb;
  }
  [play-list] section.hero .description {
    margin-top: 0.5rem;
    font-size: 1.625rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-list] section.list {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  [play-list] section.list .sticky {
    margin-top: 7rem;
  }
  [play-list] section.list .sub-categories .swiper-slide {
    margin-top: 0rem;
    margin-right: 0.75rem;
    margin-bottom: 0rem;
    margin-left: 0.75rem;
    margin-top: 1.25rem;
  }
  [play-list] section.list .sub-categories .swiper-slide a {
    font-size: 1.125rem;
    padding-top: 0.25rem;
    padding-right: 0.6875rem;
    padding-bottom: 0.375rem;
    padding-left: 0.6875rem;
  }
  [play-list] section.list .lowest-categories {
    margin-top: 12.4375rem;
  }
  [play-list] section.list .lowest-categories [scroll-menu] .swiper-slide + .swiper-slide {
    margin-left: 2rem;
  }
  [play-list] section.list .lowest-categories .swiper-slide a {
    font-size: 1.3125rem;
  }
  [play-list] section.list .lowest-categories + ul {
    padding-top: 3rem;
  }
  [play-list] section.list > ul {
    column-gap: 33px;
    padding-top: 12.5rem;
  }
  [play-list] section.list > ul > li {
    margin-bottom: 2.0625rem;
  }
  [play-list] section.list > ul > li .text {
    padding-top: 3rem;
    padding-right: 2.5rem;
    padding-bottom: 3rem;
    padding-left: 2.5rem;
  }
  [play-list] section.list > ul > li .text p:first-of-type {
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  [play-list] section.list > ul > li .text p:first-of-type span {
    margin-left: 1rem;
  }
  [play-list] section.list > ul > li .text p:last-of-type {
    font-size: 1.75rem;
    line-height: 0.098125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.57;
  }
  [play-list] section.list > ul > li .sns-wrap [sns-share] {
    width: 2rem;
    height: 2rem;
    margin-top: 1.1875rem;
    margin-right: 1.3125rem;
    margin-bottom: 1.1875rem;
    margin-left: 1.3125rem;
  }
  [play-list] section.list .view-more {
    margin-top: 11.25rem;
  }
  [play-list] section.list .view-more a span {
    font-size: 1.875rem;
    line-height: 0.09375rem;
    line-height: 1.5;
  }
  [play-list] section.list .view-more a span:last-of-type {
    margin-left: 1.125rem;
  }
  [play-list] section.list .view-more a i {
    width: 2rem;
    height: 2rem;
    margin-right: 1.625rem;
  }
}
